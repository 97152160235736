import axios from 'axios';
import { API_URI } from '../constants/api';
import { toast } from 'react-toastify';
import { R_ERROR_500 } from '../constants/messages';

const configureApi = () => {
  const instance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
    baseURL: API_URI,
  });

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    if (token && config.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      config.headers['Timestamp'] = new Date().toISOString();
    }
    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      // if (response.data.result === 'failed') {
      //   toast.error(R_ERROR_500);
      // }
      return response.data;
    },
    error => {
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error, please try again later!');
      }
      if (error.response.status === 500) {
        toast.error(R_ERROR_500);
      }
      if (error.response.status === 403) {
        toast.error(error.message);
        setTimeout(() => location.reload(), 1500);
      } else if (error.response.status === 404) {
        toast.error(error.message);
      }
      throw error;
    },
  );

  return instance;
};

const api = configureApi();
export default api;
